.timers-container {
    @apply 
    grid grid-cols-3 gap-4;
}

.timers-item {
    @apply 
    font-black
    text-4xl
    flex flex-col items-center
    text-white;
}

.timers-item span {
    @apply 
    font-bold
    text-lg;
}