@import './styles/Layouts.scss';
@import './styles/Timers.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Merienda', cursive;
}

#background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}